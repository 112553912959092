ion-modal.auto-height {
  --height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
ion-modal.auto-height::part(content) {
  position: relative;
  display: block;
  contain: content;
}
ion-modal.auto-height .inner-content {
  overflow: auto;

  swiper-container {
    height: auto;
  }
}
