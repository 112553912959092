@use "sass:meta";
@tailwind base;
@tailwind components;
@tailwind utilities;

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import '@ionic/angular/css/palettes/dark.system.css';

@import "leaflet/dist/leaflet.css";

@include meta.load-css("theme/action-sheet");
@include meta.load-css("theme/swiper");
@include meta.load-css("theme/angular-calendar");

ion-card {
  --background: var(--ion-background-color);
  --border-radius: 0;
  --border-color: #222222;
  box-shadow: none;
}

ion-card-content, ion-card-header {
  background-color: var(--ion-background-color);
}

ion-list ion-item {
  padding: 0;
}

ion-fab-button[data-desc] {
  position: relative;
}

ion-fab-button[data-desc]::after {
  position: absolute;
  content: attr(data-desc);
  z-index: 1;
  right: 50px;
  bottom: 7px;
  color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54);
  background-color: var(--ion-color-light, #fff);
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;
}

.card-header-image {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  object-position: top;
  border-radius: 1.2rem;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--tw-prose-headings);
  @apply font-bold leading-relaxed;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1.15rem;
}

a {
  text-decoration: none!important;
  color: var(--ion-color-primary)!important;
}

.text {
  @apply leading-normal;
  ul {
    @apply list-disc list-inside mb-3;
  }
  ol {
    @apply list-decimal list-inside;
  }
  p {
    @apply mb-3;
  }
  strong, b {
    @apply font-bold;
  }
  em, i {
    @apply italic;
  }
  blockquote {
    @apply border-l-4 border-gray-300 pl-4 italic;
  }
}

.map {
  padding: 0;
  height: 250px;
  @apply md:h-[500px];
}

.map-large {
  padding: 0;
  height: 80vh;
}

.plt-ios {
  #places-map {
    @apply h-[calc(100vh-44px-51px-52px-44px)] sm:h-[calc(100vh-60px-56px-56px)];
  }
}

.plt-android {
  #places-map {
    @apply h-[calc(100vh-60px-56px-56px-57px)] sm:h-[calc(100vh-60px-56px-56px)];
  }
}

.plt-desktop {
  #places-map {
    @apply h-[calc(100vh-44px-51px-52px-44px)] sm:h-[calc(100vh-60px-56px-56px)];
  }
}

:host .toolbar-title {
  width: fit-content;
}
