@use 'angular-calendar/scss/angular-calendar';

@media (prefers-color-scheme: dark) {
  // First define some global color variables for your app, these are just for the demo, they can be anything you like
  $bg-dark-primary: --ion-background-color;
  $bg-dark-secondary: --ion-background-color;
  $bg-active: #2c343a;
  $text-color: ---ion-color-dark;
  $border-color: rgb(0 0 0 / 60%);

  // Call the calendar mixin with a sass color map of your theme. Every property is optional.
  // For a list of all variables and how they are used,
  // see https://github.com/mattlewis92/angular-calendar/tree/main/projects/angular-calendar/src/variables.scss
  @include angular-calendar.cal-theme(
      (
        bg-primary: $bg-dark-primary,
        bg-secondary: $bg-dark-secondary,
        weekend-color: indianred,
        bg-active: $bg-active,
        border-color: $border-color,
        gray: $bg-dark-secondary,
        white: --ion-background-color-step-200,
        today-bg: $bg-dark-secondary,
        event-color-primary: $bg-dark-secondary,
      )
  );

  // How to override a non themable property, this was copied from chrome -> inspect element -> styles panel
  .cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
    opacity: 0.15;
  }

  // Everything else below is just themes the demo, it's probably not relevant to your app
  .cal-month-view {
    background-color: var(--ion-background-color);
    color: var(--ion-text-color);

    .cal-cell-row:hover {
      background-color: var(--ion-background-color-step-200);
    }

    .cal-day-cell.cal-today {
      background-color: var(--ion-background-color-step-300);
    }
  }

  .card {
    border-color: $border-color;
  }

  .card-header {
    background-color: $bg-dark-secondary;
  }

  .card-body {
    background-color: $bg-dark-primary;
  }

  .nav-tabs {
    border-bottom-color: $border-color;
  }

  .nav-tabs .nav-link.active {
    background-color: $bg-dark-primary;
    color: $text-color;
    border-color: $border-color;
  }

  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: $border-color;
  }
}
